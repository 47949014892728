export default {
    methods: {
        numberStringDecimal(val) {
            var str = val ? parseFloat(val.toString().replace(/,/g, "")) : 0.0;
            return str.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
        },
        numberStringDecimalfour(val) {
            var str = val ? parseFloat(val.toString().replace(/,/g, "")) : 0.0;
            return str.toFixed(4).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
        },
        oddsDecimalfour(val) {
            var str = val ? parseFloat(val.toString().replace(/,/g, "")) : 0.0;
            return str.toLocaleString("en-US", { maximumFractionDigits: 2, minimumFractionDigits: 2 });
            // return val.toLocaleString('en-US', {minimumFractionDigits: 4});
        },
        oddsDecimal(val, digits) {
            var str = val ? parseFloat(val.toString().replace(/,/g, "")) : 0.0;
            return str.toLocaleString("en-US", { maximumFractionDigits: digits, minimumFractionDigits: digits });
            // return val.toLocaleString('en-US', {minimumFractionDigits: 4});
        },
        // numberString(val) {
        //     var str = val ? val.toString() : "";

        //     // check if have decimal
        //     if (val === 0) {
        //         return '0'
        //     } else {
        //         return (
        //             (val < 0 ? "-" : "") +
        //             str
        //             .replace(/^0+/, "")
        //             .replace(/[^0-9]/g, "")
        //             .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
        //         );
        //     }

        // },
        numberString(val) {
            // Round up the value if it's a number
            const roundedVal = typeof val === 'number' ? Math.ceil(val) : val;

            // Convert the value to a string, or use an empty string if the value is undefined or null
            const str = roundedVal != null ? roundedVal.toString() : "";

            // Check if the value is zero and return '0'
            if (roundedVal === 0) {
                return '0';
            } else {
                // Remove leading zeros and any non-digit characters,
                // then add commas for thousands separator
                return (roundedVal < 0 ? "-" : "") + str.replace(/^0+/, "").replace(/\D/g, "").replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
            }
        },
        stringNumber(val) {
            return parseInt(val.replace(/[^0-9]/g, ""));
        },
        formattedNumber(val) {
            return val.replace(
                // /(\d{4})(\d{3})(\d{3})/,
                // "$1-$2-$3"
                /(\d{2})(\d{3})(\d{3})(\d{4})/,
                "$1-$2-$3-$4"
            );
        },
        inputNumToString(val) {
            var str = val ? val.toString() : ''

            // check if have decimal

            return (val < 0 ? '-' : '') + str.replace(/^0+/, '')
                .replace(/[^0-9]/g, '')
                .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
        },
        numToString(val) {
            var str = val ? val.toString() : ''

            // check if have decimal

            return val > 0 ? (val < 0 ? '-' : '') + str.replace(/^0+/, '')
                .replace(/[^0-9]/g, '')
                .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') : '0'
        },
        stringToNum(val) {
            return parseInt(val.replace(/[^0-9]/g, ''))
        },
    },
};