import axios from "axios";
import $api from "../api";


export const actions = {
  getList({ commit, rootGetters, dispatch }, filename) {
    return new Promise(function (resolve) {
      axios
        .get(`/data/${filename}.json`, {
          headers: {
            Authorization: rootGetters["auth/bearer_token"],
          },
        })
        .then(function (res) {
          if (res.status == 200) {
            resolve(res);
          }
        })
        .catch(function (err) {
          console.log(err, "err");
          if (err.response.status == 401) {
            dispatch("auth/logout", {}, { root: true }).then(() => { });
          }
        });
    });
  },
  getGameList({ commit, rootGetters, dispatch }, pl) {
    return new Promise(function (resolve) {
      axios
        .get(`/data/updated-slot-gamelist/${pl.game_id}.json`, {
          headers: {
            Authorization: rootGetters["auth/bearer_token"],
          },
        })
        .then(function (res) {
          if (res.status == 200) {
            resolve(res);
          }
        })
        .catch(function (err) {
          console.log(err, "err");
          if (err.response.status == 401) {
            dispatch("auth/logout", {}, { root: true }).then(() => { });
          }
        });
    });
  },
  getGameList2({ commit, rootGetters, dispatch }, pl) {
    return new Promise(function (resolve) {
      axios
        .get(`/data/slot2-game-list.json`, {
          headers: {
            Authorization: rootGetters["auth/bearer_token"],
          },
        })
        .then(function (res) {
          if (res.status == 200) {
            resolve(res);
          }
        })
        .catch(function (err) {
          console.log(err, "err");
          if (err.response.status == 401) {
            dispatch("auth/logout", {}, { root: true }).then(() => { });
          }
        });
    });
  },
  getGameLaunch({ commit, rootGetters, dispatch }, pl) {
    return new Promise(function (resolve) {
      $api
        .get(`player/game-launch?game_id=${pl.game_id}&vendor=${pl.vendor}`, {
          headers: {
            Authorization: rootGetters["auth/bearer_token"],
          },
        })
        .then(function (res) {
          if (res.status == 200) {
            // console.log("gamelaunch", res);
            // commit("setGameLaunch", res.data.data);
            resolve(res);
          }
        })
        .catch(function (err) {
          if (err.response.status == 401) {
            resolve(err.response);
            // dispatch("auth/logout", {}, { root: true }).then(() => {});

          }
          if (err.response.status == 422) {
            console.log(err);
            resolve(err.response);
          }
        });
    });
  },
  getGameLaunch2({ commit, rootGetters, dispatch }, pl) {
    return new Promise(function (resolve) {
      $api
        .post(`player/kplay/auth`, pl, {
          headers: {
            Authorization: rootGetters["auth/bearer_token"],
          },
        })
        .then(function (res) {
          if (res.status == 200) {
            // console.log("gamelaunch", res);
            // commit("setGameLaunch", res.data.data);
            resolve(res);
          }
        })
        .catch(function (err) {
          resolve(err.response);
        });
    });
  },
};