import $api from "../api";

export const actions = {
    getList({ rootGetters, dispatch }, pl) {
        return new Promise(function (resolve) {
            $api
                .get(`player/transactions?${pl.page_no ? "page=" + pl.page_no : ""}${pl.limit ? "&limit=" + pl.limit : "&limit=50"}${pl.type ? "&type=" + pl.type : ""}`, {
                    headers: {
                        Authorization: rootGetters["auth/bearer_token"],
                    },
                })
                .then(function (res) {
                    if (res.status == 200) {
                        resolve(res.data.data);
                    }
                })
                .catch(function (err) {
                    if (err.response.status == 401) {
                        dispatch("auth/logoutUser", {}, { root: true }).then(() => { });
                    }
                });
        });
    },
    deposit({ rootGetters, dispatch }, pl) {
        return new Promise(function (resolve) {
            $api
                .post("player/transaction/deposit", pl, {
                    headers: {
                        Authorization: rootGetters["auth/bearer_token"],
                    },
                })
                .then(function (res) {
                    if (res.status == 200) {
                        resolve({
                            success: true,
                            data: res.data
                        });
                    }
                })
                .catch(function (err) {
                    if (err.response.status == 401) {
                        dispatch("auth/logout", {}, { root: true }).then(() => { });
                    }
                    resolve({
                        success: false,
                        data: err.response.data
                    });

                });
        });
    },
    withdraw({ rootGetters, dispatch }, pl) {
        return new Promise(function (resolve) {
            $api
                .post("player/transaction/withdraw", pl, {
                    headers: {
                        Authorization: rootGetters["auth/bearer_token"],
                    },
                })
                .then(function (res) {
                    if (res.status == 200) {
                        resolve({
                            success: true,
                            data: res.data
                        });
                    }
                })
                .catch(function (err) {
                    if (err.response.status == 401) {
                        dispatch("auth/logout", {}, { root: true }).then(() => { });
                    }
                    resolve({
                        success: false,
                        data: err.response.data
                    });
                });
        });
    },
    getBank({ rootGetters, dispatch }, pl) {
        return new Promise(function (resolve) {
            $api
                .get("player/transaction/bank", {
                    headers: {
                        Authorization: rootGetters["auth/bearer_token"],
                    },
                })
                .then(function (res) {
                    if (res.status == 200) {
                        resolve(res.data.data);
                    }
                })
                .catch(function (err) {
                    if (err.response.status == 401) {
                        dispatch("auth/logout", {}, { root: true }).then(() => { });
                    }
                    resolve({
                        success: false,
                        data: err.response.data
                    });
                });
        });
    },
};
