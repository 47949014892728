<template>
    <div class="main-container">
        <h3 class="games-block-heading">
            <a href="javascript:void(0);">{{ $t('game list') }}</a>
            <!-- <a
                href="javascript:void(0);"
                class="games-block-more"
                >See More</a
            > -->
        </h3>
        <div class="providers-block">
            <div class="provider-list scrollable">
                <div class="provider-list-group single">
                    <a href="javascript:void(0);">
                        <img
                            src="@/assets/img/thumbnails/providers/bgaming.webp"
                            alt=""
                    /></a>
                    <a href="javascript:void(0);">
                        <img
                            src="@/assets/img/thumbnails/providers/evolution.webp"
                            alt=""
                    /></a>
                    <a href="javascript:void(0);">
                        <img
                            src="@/assets/img/thumbnails/providers/nolimit.webp"
                            alt=""
                    /></a>
                    <a href="javascript:void(0);">
                        <img
                            src="@/assets/img/thumbnails/providers/playngo.webp"
                            alt=""
                    /></a>
                    <a href="javascript:void(0);">
                        <img
                            src="@/assets/img/thumbnails/providers/pragmaticplay.webp"
                            alt=""
                    /></a>
                    <a href="javascript:void(0);">
                        <img
                            src="@/assets/img/thumbnails/providers/relax.webp"
                            alt=""
                    /></a>
                </div>
                <div class="provider-list-group multiple">
                    <a href="javascript:void(0);">
                        <img
                            src="@/assets/img/thumbnails/providers/bgaming.webp"
                            alt=""
                    /></a>

                    <a href="javascript:void(0);">
                        <img
                            src="@/assets/img/thumbnails/providers/evolution.webp"
                            alt=""
                    /></a>
                    <a href="javascript:void(0);">
                        <img
                            src="@/assets/img/thumbnails/providers/nolimit.webp"
                            alt=""
                    /></a>
                    <a href="javascript:void(0);">
                        <img
                            src="@/assets/img/thumbnails/providers/playngo.webp"
                            alt=""
                    /></a>
                    <a href="javascript:void(0);">
                        <img
                            src="@/assets/img/thumbnails/providers/pragmaticplay.webp"
                            alt=""
                    /></a>
                    <a href="javascript:void(0);">
                        <img
                            src="@/assets/img/thumbnails/providers/relax.webp"
                            alt=""
                    /></a>
                </div>
                <div class="provider-list-group multiple">
                    <a href="javascript:void(0);">
                        <img
                            src="@/assets/img/thumbnails/providers/bgaming.webp"
                            alt=""
                    /></a>
                    <a href="javascript:void(0);">
                        <img
                            src="@/assets/img/thumbnails/providers/evolution.webp"
                            alt=""
                    /></a>
                    <a href="javascript:void(0);">
                        <img
                            src="@/assets/img/thumbnails/providers/nolimit.webp"
                            alt=""
                    /></a>
                    <a href="javascript:void(0);">
                        <img
                            src="@/assets/img/thumbnails/providers/playngo.webp"
                            alt=""
                    /></a>
                    <a href="javascript:void(0);">
                        <img
                            src="@/assets/img/thumbnails/providers/pragmaticplay.webp"
                            alt=""
                    /></a>
                    <a href="javascript:void(0);">
                        <img
                            src="@/assets/img/thumbnails/providers/relax.webp"
                            alt=""
                    /></a>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'ProviderGrid',
};
</script>
