<template>
    <a
        href="javascript:void(0)"
        class="floating-button"
        @click="changeLocale">
        <img
            :src="require(`@/assets/img/flags/${$i18n.locale}.png`)"
            alt="" />
    </a>
</template>

<script>
export default {
    name: 'FloatingButton',
    methods: {
        changeLocale() {
            this.$i18n.locale = this.$i18n.locale == 'en' ? 'ko' : 'en';
            $cookies.set('locale', this.$i18n.locale);
        },
    },
};
</script>

