<template>
    <router-view />
</template>

<script>
import ui from '@/mixins/ui.js';
import audio from '@/mixins/audio.js';
import { mapMutations, mapGetters, mapActions } from 'vuex';
export default {
    name: 'App',
    mixins: [ui, audio],
    data() {
        return {
            polling: null,
        };
    },
    computed: {
        ...mapGetters('auth', ['authenticated']),
    },
    watch: {
        authenticated(newVal) {
            console.log(newVal);
            if (!newVal) {
                this.stopPolling();
            } else {
                this.startPolling();
            }
        },
    },
    methods: {
        ...mapActions('auth', ['logoutUser']),
        async profileInterval() {
            await this.$store.dispatch('auth/getProfile');
        },
        startPolling() {
            this.polling = setInterval(this.profileInterval, 10000);
        },
        stopPolling() {
            clearInterval(this.polling);
        },
    },
    mounted() {
        var vm = this;
        vm.initializeAudio();
        var date = new Date();
        var currentDateTime = date.getTime();
        var cookieTime = $cookies.get('cookieTime');
        var cookieDateInSecs = Math.round(cookieTime / 1000);
        var currentDateInSecs = Math.round(currentDateTime / 1000);
        var totalSeconds = currentDateInSecs - cookieDateInSecs;
        if (vm.authenticated) {
            vm.$store.dispatch('auth/getProfile');
            if (totalSeconds > 10 && !window.opener) {
                this.logoutUser();
            }
            vm.startPolling();
        }
        if ($cookies.get('locale')) {
            vm.$i18n.locale = $cookies.get('locale');
        }
        window.addEventListener('message', function (e) {
            if (e.origin === window.origin) {
                if (e.data.event == 'close-game' && !window.opener) {
                    vm.$store.dispatch('auth/getProfile');
                    vm.$store.commit('closeWindow', e.data.name);
                }
                if (e.data.event == 'open-game' && !window.opener) {
                    // vm.$store.commit("setPopups");
                    vm.$store.commit('addWindow', {
                        popup: e.data.name,
                        gameType: e.data.gameType,
                    });
                }
            }
        });
    },
};
</script>
