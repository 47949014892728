export default {
    data() {
        return {
            interacted: false,
            defaultPlayer: null,
            depositPlayer: null,
            withdrawPlayer: null,
            audioLoop: null,
        };
    },
    watch: {
        '$store.state.auth.profile.recent_announcement': {
            async handler(n) {
                // clearInterval(this.audioLoop)
                this.audioLoop == null
                if (n > 0) {
                    this.playAudio();
                    // this.audioLoop = setInterval(() => {
                    //     this.playAudio()
                    // }, 10000)
                }
                else {
                    clearInterval(this.audioLoop)
                    this.audioLoop == null
                }
            },
            deep: true,
        },
    },
    methods: {
        initializeAudio() {
            this.defaultPlayer = new Audio(require('@/assets/audio/notification.mp3'));
            this.defaultPlayer.volume = 0.3;
            // this.depositPlayer = new Audio(require('@/assets/audio/so-proud-notification.mp3'));
            // this.depositPlayer.volume = 0.3;
            // this.withdrawPlayer = new Audio(require('@/assets/audio/playful-notification.mp3'));
            // this.withdrawPlayer.volume = 0.3;
        },
        playAudio(type) {
            if (!this.interacted) return;
            try {
                switch (type) {
                    case 'deposit': this.depositPlayer.play();
                        break;
                    case 'withdraw': this.withdrawPlayer.play();
                        break;
                    default: this.defaultPlayer.play();
                        break;
                }
            } catch (e) {
                console.log(e);
            }
        },
        resetAudio() {
            this.defaultPlayer = null
        },
    },
    mounted() {
        document.addEventListener('click', () => {
            this.interacted = true;
        });
    },
}