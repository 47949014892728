<template>
    <div class="screen-loader">
        <img
            src="@/assets/img/loader2.svg"
            alt="" />
    </div>
</template>

<script>
export default {
    name: 'ScreenLoader',
};
</script>
