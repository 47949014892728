<template>
    <div class="layout">
        <TopNav />
        <main>
            <slot />
            <FooterOriginal />
        </main>
        <BottomNav />
    </div>
    <AuthModal />
    <FloatingButton v-if="0" />
</template>

<script>
import TopNav from '@/components/TopNav.vue';
import FooterOriginal from '@/components/FooterOriginal.vue';
import BottomNav from '@/components/BottomNav.vue';
import AuthModal from '@/components/AuthModal.vue';
import FloatingButton from '@/components/FloatingButton.vue';
import ScreenLoader from '@/components/ScreenLoader.vue';
export default {
    name: 'Layout',
    components: {
        TopNav,
        FooterOriginal,
        BottomNav,
        AuthModal,
        FloatingButton,
        ScreenLoader,
    },
};
</script>
