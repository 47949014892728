<template>
    <div class="bottom-nav">
        <router-link to="/transaction/deposit">
            <div>
                <img
                    src="@/assets/img/svg-icons/deposit-icon.svg"
                    alt="" />
            </div>
            <span>{{ $t('deposit') }}</span>
        </router-link>

        <router-link to="/transaction/withdraw">
            <div>
                <img
                    src="@/assets/img/svg-icons/withdraw-icon.svg"
                    alt="" />
            </div>
            <span>{{ $t('withdraw') }}</span>
        </router-link>
        <router-link to="/transaction">
            <div>
                <img
                    src="@/assets/img/svg-icons/bank-icon.svg"
                    alt="" />
            </div>
            <span>{{ $t('transactions') }}</span>
        </router-link>
        <router-link
            to="/?view=games"
            class="middle">
            <div>
                <img
                    src="@/assets/img/svg-icons/slots.svg"
                    alt="" />
            </div>
            <span>{{ $t('game') }}</span>
        </router-link>

        <router-link to="/bet">
            <div>
                <img
                    src="@/assets/img/svg-icons/history-icon.svg"
                    alt="" />
            </div>
            <span>{{ $t('bet history') }}</span>
        </router-link>
        <router-link to="/coupon"
            ><span
                class="notif-badge"
                v-if="profile.coupon.unclaimed"
                >{{ profile.coupon.unclaimed }}</span
            >
            <div>
                <img
                    src="@/assets/img/svg-icons/coupon-icon.svg"
                    alt="" />
            </div>
            <span>{{ $t('coupon') }}</span>
        </router-link>
        <router-link to="/ticket"
            ><span
                class="notif-badge"
                v-if="profile.ticket.unread"
                >{{ profile.ticket.unread }}</span
            >
            <div>
                <svg
                    aria-hidden="true"
                    width="16"
                    height="15.2"
                    viewBox="0 0 20 19"
                    focusable="false"
                    class="fa-icon">
                    <g>
                        <path
                            fill="none"
                            d="M6.75922 9.17143C6.75922 9.37656 6.59222 9.54286 6.38621 9.54286C6.1802 9.54286 6.0132 9.37656 6.0132 9.17143C6.0132 8.96629 6.1802 8.8 6.38621 8.8C6.59222 8.8 6.75922 8.96629 6.75922 9.17143ZM6.75922 9.17143H6.38621M10.4893 9.17143C10.4893 9.37656 10.3223 9.54286 10.1163 9.54286C9.91031 9.54286 9.74331 9.37656 9.74331 9.17143C9.74331 8.96629 9.91031 8.8 10.1163 8.8C10.3223 8.8 10.4893 8.96629 10.4893 9.17143ZM10.4893 9.17143H10.1163M14.2194 9.17143C14.2194 9.37656 14.0524 9.54286 13.8464 9.54286C13.6404 9.54286 13.4734 9.37656 13.4734 9.17143C13.4734 8.96629 13.6404 8.8 13.8464 8.8C14.0524 8.8 14.2194 8.96629 14.2194 9.17143ZM14.2194 9.17143H13.8464M19.0686 9.17143C19.0686 13.6844 15.0605 17.3429 10.1163 17.3429C9.23356 17.3429 8.38064 17.2262 7.57504 17.0089C6.6052 17.6874 5.42335 18.0857 4.14815 18.0857C3.94999 18.0857 3.75401 18.0761 3.56095 18.0573C3.40213 18.0419 3.24476 18.0202 3.08958 17.9926C3.57055 17.4273 3.91217 16.7413 4.06249 15.9867C4.15266 15.534 3.93001 15.0944 3.59792 14.7725C2.08883 13.3098 1.16406 11.3392 1.16406 9.17143C1.16406 4.65847 5.17212 1 10.1163 1C15.0605 1 19.0686 4.65847 19.0686 9.17143Z"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"></path>
                    </g>
                </svg>
            </div>
            <span>{{ $t('inquiry') }}</span>
        </router-link>
    </div>
</template>

<script>
export default {
    name: 'BottomNav',
    computed: {
        profile() {
            return this.$store.getters['auth/profile'];
        },
    },
};
</script>
