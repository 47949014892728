<template>
    <div id="games" class="game-tabs">
        <button type="button" :class="menu=='slots'?'active':''" @click="menu='slots'"><img src="@/assets/img/game-menu/btn-slots.png" alt=""></button>
        <button type="button" :class="menu=='casino'?'active':''" @click="menu='casino'"><img src="@/assets/img/game-menu/btn-casino.png" alt=""></button>
    </div>
    <div v-if="0"
        id="hilton-games"
        class="main-container">
        <h3 class="games-block-heading">
            <a href="javascript:void(0);">{{ $t('hilton games') }}</a
            >
            <!-- <a
                href="javascript:void(0);"
                class="games-block-more"
                @click="goTo('hilton-games')"
                >{{ $t('see more') }}</a
            > -->
        </h3>
        <div class="games-block">
            <div class="game-list scrollable">
                <a
                    data-title="baccarat"
                    href="javascript:void(0);"
                    @click="handleClick({}, 'hilton')"
                    ><div class="overlay">
                        <svg
                            width="36"
                            height="36"
                            aria-hidden="true"
                            viewBox="0 0 1109 1109"
                            focusable="false"
                            class="cg-play fa-icon">
                            <g>
                                <path
                                    d="M612 97c80,10 122,29 191,69l80 64c30,32 52,62 75,100 29,50 49,117 55,175l4 53c-1,8 -1,18 -2,28 -4,37 -4,41 -12,75 -7,36 -15,55 -29,86 -18,43 -41,72 -68,106 -5,7 -8,9 -13,15 -6,5 -8,9 -14,15 -43,41 -66,55 -118,84 -43,25 -104,41 -155,46l-53 4c-50,-1 -102,-11 -150,-26l-40 -16c-13,-6 -25,-13 -38,-20 -39,-24 -50,-32 -83,-61 -23,-22 -50,-51 -67,-77l-37 -63c-2,-4 -3,-7 -5,-10l-28 -87c-5,-16 -7,-33 -9,-50l-4 -49c-1,-22 7,-82 13,-104 2,-9 3,-16 5,-25 9,-30 18,-55 33,-82 24,-46 48,-80 83,-117 6,-6 11,-9 16,-14 60,-59 148,-101 230,-116 10,-2 15,-2 25,-3 23,-3 43,-5 65,-5l50 5zm-612 419l0 78 1 4c2,7 0,6 3,24 3,14 4,37 9,54 3,9 4,17 6,26 12,45 28,77 47,115 11,24 47,78 65,96l56 58c4,3 4,3 8,7 23,21 77,56 105,70 32,16 72,33 106,41 39,10 63,15 103,19l7 1 78 0c0,0 1,-1 1,0l4 -1c9,-1 4,1 23,-3 31,-6 40,-5 80,-15 57,-14 136,-53 184,-90 33,-26 83,-69 106,-104 20,-29 31,-38 51,-77 19,-37 36,-74 46,-116 9,-35 16,-66 19,-102l1 -7 0 -78 -1 -7c-4,-39 -9,-65 -19,-103 -3,-14 -10,-36 -16,-49l-30 -67c-9,-17 -31,-52 -44,-68 -29,-35 -20,-27 -52,-59 -30,-30 -37,-36 -70,-60 -62,-47 -154,-83 -227,-95 -10,-2 -21,-2 -28,-4 -18,-3 -17,-1 -24,-3l-4 -1 -78 0 -58 8c-46,8 -104,26 -146,47l-42 23c-16,9 -60,40 -75,54 -6,5 -10,8 -16,15 -19,18 -48,46 -62,67 -5,6 -10,12 -14,18l-37 60c-31,60 -45,99 -58,168 -2,9 -3,19 -4,28 -3,16 -1,14 -2,22 0,1 -1,1 -1,2 0,0 0,1 0,1l-1 3z"></path>
                                <path
                                    d="M423 349l0 413c0,25 15,43 38,42 18,-1 38,-21 52,-30 8,-6 16,-11 24,-18l214 -156c22,-16 38,-19 38,-48 0,-17 -28,-37 -42,-46l-199 -144c-7,-5 -14,-11 -21,-15 -30,-22 -67,-60 -94,-29 -6,7 -10,19 -10,31z"></path>
                            </g>
                        </svg>
                        <div class="overlay-info">
                            <div class="game-titles">
                                <h3 class="game-title">{{$t('baccarat')}}</h3>
                                <h4>Hilton Games</h4>
                            </div>
                            <div class="game-stats"></div>
                        </div>
                    </div>
                    <img
                        v-if="selected_game_id == 'hilton' && loading"
                        class="loader"
                        src="@/assets/img/loader.svg"
                        alt="" />
                    <img
                        :src="require('@/assets/img/thumbnail/hilton/hilton-logo.png')"
                        alt="" />
                    <p
                        style="
                            position: absolute;
                            width: 100%;
                            /* height: 10px; */
                            z-index: 2;
                            color: #fff;
                            font-size: 12px;
                            top: 187px;
                        ">
                        {{$t('baccarat')}}
                    </p>
                </a>
            </div>
        </div>
    </div>
    <div
        v-if="['all', 'honorlink'].includes( $store.getters['auth/profile'].menu.table)&& menu == 'casino'"
        id="casino1"
        class="main-container">
        <!-- <h3 class="games-block-heading">
            <a href="javascript:void(0);">{{ $t('live casino') }}</a
            ><a
                href="javascript:void(0);"
                class="games-block-more"
                @click="goTo('casino1')"
                >{{ $t('see more') }}</a
            >
        </h3> -->
        <div class="games-block">
            <div class="game-featured">
                <div class="game-featured-image">
                    <img
                        src="@/assets/img/thumbnail/casino1/evolution.png"
                        alt="" />
                </div>
                <div class="game-featured-content">
                    <div class="game-featured-content-provider">
                        <a href="javascript:void(0);"
                            ><img
                                src="@/assets/img/thumbnails/providers/bitsler.webp"
                                alt=""
                        /></a>
                    </div>
                    <div class="game-featured-content-text">
                        <h4>Twist</h4>
                        <p>Let's make a twist and spin into Bitsler games!</p>
                    </div>
                    <div class="game-featured-content-button">
                        <a
                            href="javascript:void(0);"
                            class="btn btn-outline"
                            >Play Now</a
                        >
                    </div>
                </div>
            </div>
            <div class="game-list scrollable">
                <a
                    :data-title="g.name"
                    href="javascript:void(0);"
                    v-for="(g, i) in firstTen($store.state.game.casino1)"
                    @click="handleClick(g, 'casino1')"
                    :key="i"
                    ><div class="overlay">
                        <svg
                            width="36"
                            height="36"
                            aria-hidden="true"
                            viewBox="0 0 1109 1109"
                            focusable="false"
                            class="cg-play fa-icon">
                            <g>
                                <path
                                    d="M612 97c80,10 122,29 191,69l80 64c30,32 52,62 75,100 29,50 49,117 55,175l4 53c-1,8 -1,18 -2,28 -4,37 -4,41 -12,75 -7,36 -15,55 -29,86 -18,43 -41,72 -68,106 -5,7 -8,9 -13,15 -6,5 -8,9 -14,15 -43,41 -66,55 -118,84 -43,25 -104,41 -155,46l-53 4c-50,-1 -102,-11 -150,-26l-40 -16c-13,-6 -25,-13 -38,-20 -39,-24 -50,-32 -83,-61 -23,-22 -50,-51 -67,-77l-37 -63c-2,-4 -3,-7 -5,-10l-28 -87c-5,-16 -7,-33 -9,-50l-4 -49c-1,-22 7,-82 13,-104 2,-9 3,-16 5,-25 9,-30 18,-55 33,-82 24,-46 48,-80 83,-117 6,-6 11,-9 16,-14 60,-59 148,-101 230,-116 10,-2 15,-2 25,-3 23,-3 43,-5 65,-5l50 5zm-612 419l0 78 1 4c2,7 0,6 3,24 3,14 4,37 9,54 3,9 4,17 6,26 12,45 28,77 47,115 11,24 47,78 65,96l56 58c4,3 4,3 8,7 23,21 77,56 105,70 32,16 72,33 106,41 39,10 63,15 103,19l7 1 78 0c0,0 1,-1 1,0l4 -1c9,-1 4,1 23,-3 31,-6 40,-5 80,-15 57,-14 136,-53 184,-90 33,-26 83,-69 106,-104 20,-29 31,-38 51,-77 19,-37 36,-74 46,-116 9,-35 16,-66 19,-102l1 -7 0 -78 -1 -7c-4,-39 -9,-65 -19,-103 -3,-14 -10,-36 -16,-49l-30 -67c-9,-17 -31,-52 -44,-68 -29,-35 -20,-27 -52,-59 -30,-30 -37,-36 -70,-60 -62,-47 -154,-83 -227,-95 -10,-2 -21,-2 -28,-4 -18,-3 -17,-1 -24,-3l-4 -1 -78 0 -58 8c-46,8 -104,26 -146,47l-42 23c-16,9 -60,40 -75,54 -6,5 -10,8 -16,15 -19,18 -48,46 -62,67 -5,6 -10,12 -14,18l-37 60c-31,60 -45,99 -58,168 -2,9 -3,19 -4,28 -3,16 -1,14 -2,22 0,1 -1,1 -1,2 0,0 0,1 0,1l-1 3z"></path>
                                <path
                                    d="M423 349l0 413c0,25 15,43 38,42 18,-1 38,-21 52,-30 8,-6 16,-11 24,-18l214 -156c22,-16 38,-19 38,-48 0,-17 -28,-37 -42,-46l-199 -144c-7,-5 -14,-11 -21,-15 -30,-22 -67,-60 -94,-29 -6,7 -10,19 -10,31z"></path>
                            </g>
                        </svg>
                        <div class="overlay-info">
                            <div class="game-titles">
                                <h3 class="game-title">{{ g.name }}</h3>
                                <h4>{{ g.vendor }}</h4>
                            </div>
                            <div class="game-stats"></div>
                        </div>
                    </div>
                    <img
                        v-if="selected_game_id == g.id && loading"
                        class="loader"
                        src="@/assets/img/loader.svg"
                        alt="" />
                    <img
                        :src="
                            require('@/assets/img/thumbnail/casino1/' +
                                g.game_thumbnail)
                        "
                        alt="" />
                    <p class="game-name">
                        {{ g.name }}
                    </p>
                </a>
            </div>
        </div>
    </div>
    <div
        v-if=" ['all', 'honorlink'].includes($store.getters['auth/profile'].menu.slots) && menu == 'slots'"
        id="slot1"
        class="main-container">
        <!-- <h3 class="games-block-heading">
            <a href="javascript:void(0);">{{ $t('slot game') }}</a
            ><a
                href="javascript:void(0);"
                class="games-block-more"
                @click="goTo('slot1')"
                >{{ $t('see more') }}</a
            >
        </h3> -->
        <div class="games-block">
            <div class="game-featured">
                <div class="game-featured-image">
                    <img
                        src="@/assets/img/thumbnail/slot1/evoplay.png"
                        alt="" />
                </div>
                <div class="game-featured-content">
                    <div class="game-featured-content-provider">
                        <a href="javascript:void(0);"
                            ><img
                                src="@/assets/img/thumbnails/providers/bitsler.webp"
                                alt=""
                        /></a>
                    </div>
                    <div class="game-featured-content-text">
                        <h4>Twist</h4>
                        <p>Let's make a twist and spin into Bitsler games!</p>
                    </div>
                    <div class="game-featured-content-button">
                        <a
                            href="javascript:void(0);"
                            class="btn btn-outline"
                            >Play Now</a
                        >
                    </div>
                </div>
            </div>
            <div class="game-list scrollable">
                <a :data-title="g.name"
                    href="javascript:void(0);"
                    v-for="(g, i) in firstTen($store.state.game.slot1)"
                    @click="handleClick(g, 'slot1')"
                    :key="i"
                    ><div class="overlay">
                        <svg
                            width="36"
                            height="36"
                            aria-hidden="true"
                            viewBox="0 0 1109 1109"
                            focusable="false"
                            class="cg-play fa-icon">
                            <g>
                                <path
                                    d="M612 97c80,10 122,29 191,69l80 64c30,32 52,62 75,100 29,50 49,117 55,175l4 53c-1,8 -1,18 -2,28 -4,37 -4,41 -12,75 -7,36 -15,55 -29,86 -18,43 -41,72 -68,106 -5,7 -8,9 -13,15 -6,5 -8,9 -14,15 -43,41 -66,55 -118,84 -43,25 -104,41 -155,46l-53 4c-50,-1 -102,-11 -150,-26l-40 -16c-13,-6 -25,-13 -38,-20 -39,-24 -50,-32 -83,-61 -23,-22 -50,-51 -67,-77l-37 -63c-2,-4 -3,-7 -5,-10l-28 -87c-5,-16 -7,-33 -9,-50l-4 -49c-1,-22 7,-82 13,-104 2,-9 3,-16 5,-25 9,-30 18,-55 33,-82 24,-46 48,-80 83,-117 6,-6 11,-9 16,-14 60,-59 148,-101 230,-116 10,-2 15,-2 25,-3 23,-3 43,-5 65,-5l50 5zm-612 419l0 78 1 4c2,7 0,6 3,24 3,14 4,37 9,54 3,9 4,17 6,26 12,45 28,77 47,115 11,24 47,78 65,96l56 58c4,3 4,3 8,7 23,21 77,56 105,70 32,16 72,33 106,41 39,10 63,15 103,19l7 1 78 0c0,0 1,-1 1,0l4 -1c9,-1 4,1 23,-3 31,-6 40,-5 80,-15 57,-14 136,-53 184,-90 33,-26 83,-69 106,-104 20,-29 31,-38 51,-77 19,-37 36,-74 46,-116 9,-35 16,-66 19,-102l1 -7 0 -78 -1 -7c-4,-39 -9,-65 -19,-103 -3,-14 -10,-36 -16,-49l-30 -67c-9,-17 -31,-52 -44,-68 -29,-35 -20,-27 -52,-59 -30,-30 -37,-36 -70,-60 -62,-47 -154,-83 -227,-95 -10,-2 -21,-2 -28,-4 -18,-3 -17,-1 -24,-3l-4 -1 -78 0 -58 8c-46,8 -104,26 -146,47l-42 23c-16,9 -60,40 -75,54 -6,5 -10,8 -16,15 -19,18 -48,46 -62,67 -5,6 -10,12 -14,18l-37 60c-31,60 -45,99 -58,168 -2,9 -3,19 -4,28 -3,16 -1,14 -2,22 0,1 -1,1 -1,2 0,0 0,1 0,1l-1 3z"></path>
                                <path
                                    d="M423 349l0 413c0,25 15,43 38,42 18,-1 38,-21 52,-30 8,-6 16,-11 24,-18l214 -156c22,-16 38,-19 38,-48 0,-17 -28,-37 -42,-46l-199 -144c-7,-5 -14,-11 -21,-15 -30,-22 -67,-60 -94,-29 -6,7 -10,19 -10,31z"></path>
                            </g>
                        </svg>
                        <div class="overlay-info">
                            <div class="game-titles">
                                <h3 class="game-title">{{ g.name }}</h3>
                                <h4>{{ g.vendor }}</h4>
                            </div>
                            <div class="game-stats"></div>
                        </div>
                    </div>
                    <img
                        v-if="selected_game_id == g.id && loading"
                        class="loader"
                        src="@/assets/img/loader.svg"
                        alt="" />
                    <!-- <img
                        :src="
                            require('@/assets/img/thumbnail/slot1-v2/' +
                                g.game_thumbnail)
                        "
                        alt="" /> -->
                    <img
                        :src="
                            require('@/assets/img/thumbnailv3/slot/' +
                                g.game_thumbnail)
                        "
                        alt="" />
                    <p class="game-name">
                        {{ g.name }}
                    </p>
                </a>
            </div>
        </div>
    </div>
    <div
        v-if="['all', 'kplay'].includes($store.getters['auth/profile'].menu.table) && menu == 'casino'"
        id="casino2"
        class="main-container">
        <!-- <h3 class="games-block-heading">
            <a href="javascript:void(0);">{{ $t('live casino') }}</a
            ><a
                href="javascript:void(0);"
                class="games-block-more"
                @click="goTo('casino2')"
                >{{ $t('see more') }}</a
            >
        </h3> -->
        <div class="games-block">
            <div class="game-featured">
                <div class="game-featured-image">
                    <img
                        src="@/assets/img/casino-featured.webp"
                        alt="" />
                </div>
                <div class="game-featured-content">
                    <div class="game-featured-content-provider">
                        <a href="javascript:void(0);"
                            ><img
                                src="@/assets/img/thumbnails/providers/bitsler.webp"
                                alt=""
                        /></a>
                    </div>
                    <div class="game-featured-content-text">
                        <h4>Twist</h4>
                        <p>Let's make a twist and spin into Bitsler games!</p>
                    </div>
                    <div class="game-featured-content-button">
                        <a
                            href="javascript:void(0);"
                            class="btn btn-outline"
                            >Play Now</a
                        >
                    </div>
                </div>
            </div>
            <div class="game-list scrollable">
                <a
                    :data-title="g.name"
                    href="javascript:void(0);"
                    v-for="(g, i) in firstTen($store.state.game.casino2)"
                    @click="handleClick(g, 'casino2')"
                    :key="i"
                    ><div class="overlay">
                        <svg
                            width="36"
                            height="36"
                            aria-hidden="true"
                            viewBox="0 0 1109 1109"
                            focusable="false"
                            class="cg-play fa-icon">
                            <g>
                                <path
                                    d="M612 97c80,10 122,29 191,69l80 64c30,32 52,62 75,100 29,50 49,117 55,175l4 53c-1,8 -1,18 -2,28 -4,37 -4,41 -12,75 -7,36 -15,55 -29,86 -18,43 -41,72 -68,106 -5,7 -8,9 -13,15 -6,5 -8,9 -14,15 -43,41 -66,55 -118,84 -43,25 -104,41 -155,46l-53 4c-50,-1 -102,-11 -150,-26l-40 -16c-13,-6 -25,-13 -38,-20 -39,-24 -50,-32 -83,-61 -23,-22 -50,-51 -67,-77l-37 -63c-2,-4 -3,-7 -5,-10l-28 -87c-5,-16 -7,-33 -9,-50l-4 -49c-1,-22 7,-82 13,-104 2,-9 3,-16 5,-25 9,-30 18,-55 33,-82 24,-46 48,-80 83,-117 6,-6 11,-9 16,-14 60,-59 148,-101 230,-116 10,-2 15,-2 25,-3 23,-3 43,-5 65,-5l50 5zm-612 419l0 78 1 4c2,7 0,6 3,24 3,14 4,37 9,54 3,9 4,17 6,26 12,45 28,77 47,115 11,24 47,78 65,96l56 58c4,3 4,3 8,7 23,21 77,56 105,70 32,16 72,33 106,41 39,10 63,15 103,19l7 1 78 0c0,0 1,-1 1,0l4 -1c9,-1 4,1 23,-3 31,-6 40,-5 80,-15 57,-14 136,-53 184,-90 33,-26 83,-69 106,-104 20,-29 31,-38 51,-77 19,-37 36,-74 46,-116 9,-35 16,-66 19,-102l1 -7 0 -78 -1 -7c-4,-39 -9,-65 -19,-103 -3,-14 -10,-36 -16,-49l-30 -67c-9,-17 -31,-52 -44,-68 -29,-35 -20,-27 -52,-59 -30,-30 -37,-36 -70,-60 -62,-47 -154,-83 -227,-95 -10,-2 -21,-2 -28,-4 -18,-3 -17,-1 -24,-3l-4 -1 -78 0 -58 8c-46,8 -104,26 -146,47l-42 23c-16,9 -60,40 -75,54 -6,5 -10,8 -16,15 -19,18 -48,46 -62,67 -5,6 -10,12 -14,18l-37 60c-31,60 -45,99 -58,168 -2,9 -3,19 -4,28 -3,16 -1,14 -2,22 0,1 -1,1 -1,2 0,0 0,1 0,1l-1 3z"></path>
                                <path
                                    d="M423 349l0 413c0,25 15,43 38,42 18,-1 38,-21 52,-30 8,-6 16,-11 24,-18l214 -156c22,-16 38,-19 38,-48 0,-17 -28,-37 -42,-46l-199 -144c-7,-5 -14,-11 -21,-15 -30,-22 -67,-60 -94,-29 -6,7 -10,19 -10,31z"></path>
                            </g>
                        </svg>
                        <div class="overlay-info">
                            <div class="game-titles">
                                <h3 class="game-title">{{ g.name }}</h3>
                                <h4>{{ g.vendor }}</h4>
                            </div>
                            <div class="game-stats"></div>
                        </div>
                    </div>
                    <img
                        v-if="selected_game_id == g.product && loading"
                        class="loader"
                        src="@/assets/img/loader.svg"
                        alt="" />
                    <img
                        :src="
                            require('@/assets/img/thumbnail/casino2/' +
                                g.game_thumbnail)
                        "
                        alt="" />
                     <p class="game-name">
                        {{ g.name }}
                    </p>
                </a>
            </div>
        </div>
    </div>
    <div
        v-if="['all', 'kplay'].includes($store.getters['auth/profile'].menu.slots) && menu == 'slots'"
        id="slot2"
        class="main-container">
        <!-- <h3 class="games-block-heading">
            <a href="javascript:void(0);">{{ $t('slot game') }}</a>
            <a
                href="javascript:void(0);"
                class="games-block-more"
                @click="goTo('slot2')"
                >{{ $t('see more') }}</a
            >
        </h3> -->
        <div class="games-block">
            <div class="game-featured">
                <div class="game-featured-image">
                    <img
                        src="@/assets/img/casino-featured.webp"
                        alt="" />
                </div>
                <div class="game-featured-content">
                    <div class="game-featured-content-provider">
                        <a href="javascript:void(0);"
                            ><img
                                src="@/assets/img/thumbnails/providers/bitsler.webp"
                                alt=""
                        /></a>
                    </div>
                    <div class="game-featured-content-text">
                        <h4>Twist</h4>
                        <p>Let's make a twist and spin into Bitsler games!</p>
                    </div>
                    <div class="game-featured-content-button">
                        <a
                            href="javascript:void(0);"
                            class="btn btn-outline"
                            >Play Now</a
                        >
                    </div>
                </div>
            </div>
            <div class="game-list scrollable">
                <a
                    :data-title="g.prd_name"
                    href="javascript:void(0);"
                    v-for="(g, i) in firstTen($store.state.game.slot2)"
                    @click="handleClick(g, 'slot2')"
                    :key="i"
                    ><div class="overlay">
                        <svg
                            width="36"
                            height="36"
                            aria-hidden="true"
                            viewBox="0 0 1109 1109"
                            focusable="false"
                            class="cg-play fa-icon">
                            <g>
                                <path
                                    d="M612 97c80,10 122,29 191,69l80 64c30,32 52,62 75,100 29,50 49,117 55,175l4 53c-1,8 -1,18 -2,28 -4,37 -4,41 -12,75 -7,36 -15,55 -29,86 -18,43 -41,72 -68,106 -5,7 -8,9 -13,15 -6,5 -8,9 -14,15 -43,41 -66,55 -118,84 -43,25 -104,41 -155,46l-53 4c-50,-1 -102,-11 -150,-26l-40 -16c-13,-6 -25,-13 -38,-20 -39,-24 -50,-32 -83,-61 -23,-22 -50,-51 -67,-77l-37 -63c-2,-4 -3,-7 -5,-10l-28 -87c-5,-16 -7,-33 -9,-50l-4 -49c-1,-22 7,-82 13,-104 2,-9 3,-16 5,-25 9,-30 18,-55 33,-82 24,-46 48,-80 83,-117 6,-6 11,-9 16,-14 60,-59 148,-101 230,-116 10,-2 15,-2 25,-3 23,-3 43,-5 65,-5l50 5zm-612 419l0 78 1 4c2,7 0,6 3,24 3,14 4,37 9,54 3,9 4,17 6,26 12,45 28,77 47,115 11,24 47,78 65,96l56 58c4,3 4,3 8,7 23,21 77,56 105,70 32,16 72,33 106,41 39,10 63,15 103,19l7 1 78 0c0,0 1,-1 1,0l4 -1c9,-1 4,1 23,-3 31,-6 40,-5 80,-15 57,-14 136,-53 184,-90 33,-26 83,-69 106,-104 20,-29 31,-38 51,-77 19,-37 36,-74 46,-116 9,-35 16,-66 19,-102l1 -7 0 -78 -1 -7c-4,-39 -9,-65 -19,-103 -3,-14 -10,-36 -16,-49l-30 -67c-9,-17 -31,-52 -44,-68 -29,-35 -20,-27 -52,-59 -30,-30 -37,-36 -70,-60 -62,-47 -154,-83 -227,-95 -10,-2 -21,-2 -28,-4 -18,-3 -17,-1 -24,-3l-4 -1 -78 0 -58 8c-46,8 -104,26 -146,47l-42 23c-16,9 -60,40 -75,54 -6,5 -10,8 -16,15 -19,18 -48,46 -62,67 -5,6 -10,12 -14,18l-37 60c-31,60 -45,99 -58,168 -2,9 -3,19 -4,28 -3,16 -1,14 -2,22 0,1 -1,1 -1,2 0,0 0,1 0,1l-1 3z"></path>
                                <path
                                    d="M423 349l0 413c0,25 15,43 38,42 18,-1 38,-21 52,-30 8,-6 16,-11 24,-18l214 -156c22,-16 38,-19 38,-48 0,-17 -28,-37 -42,-46l-199 -144c-7,-5 -14,-11 -21,-15 -30,-22 -67,-60 -94,-29 -6,7 -10,19 -10,31z"></path>
                            </g>
                        </svg>
                        <div class="overlay-info">
                            <div class="game-titles">
                                <h3 class="game-title">{{ g.name }}</h3>
                                <h4>{{ g.vendor }}</h4>
                            </div>
                            <div class="game-stats"></div>
                        </div>
                    </div>
                    <img
                        v-if="selected_game_id == g.product && loading"
                        class="loader"
                        src="@/assets/img/loader.svg"
                        alt="" />
                    <img
                        :src="
                            require('@/assets/img/thumbnail/slot2/' +
                                g.game_thumbnail)
                        "
                        alt="" />
                     <p class="game-name">
                        {{ g.prd_name }}
                    </p>
                </a>
            </div>
        </div>
    </div>
</template>
<script>
import ui from '@/mixins/ui.js';
export default {
    name: 'Games',
    mixins: [ui],
    data() {
        return {
            loading: false,
            selected_game_id: null,
            menu: 'slots'
        };
    },
    computed: {
        authenticated() {
            return this.$store.getters['auth/authenticated'];
        },
    },
    methods: {
        firstTen(arr) {
            // return arr.slice(0, 10);
            return arr
        },
        async handleClick(game, type) {
            if (!this.authenticated) return;

            if (type == 'casino1') {
                this.selected_game_id = game.id;
                switch (game.id) {
                    case 'dgcasino':
                    case 'wmcasino':
                    case '0':
                    case 'bota':
                    case '101':
                    case 'betgame_casino':
                    case 'skywind_casino':
                        this.loading = true;
                        this.$router.push({
                            path: '/game/casino',
                            query: {
                                vendor: game.vendor,
                                game_id: game.id,
                            },
                        });
                        this.loading = false;
                        break;
                    default:
                        this.loading = true;
                        await this.launchGame(game);
                        this.loading = false;
                        break;
                }
            } else if (type == 'slot1') {
                this.selected_game_id = game.id;
                switch (game.vendor) {
                    case 'Dragoon Soft':
                    case 'Blueprint Gaming':
                    case 'GameArt':
                    case 'Habanero':
                    case 'Mobilots':
                    case 'PlayPearls':
                    case 'PlayStar':
                    case 'Thunderkick':
                    case 'Wazdan':
                    case 'CQ9':
                    case 'netent':
                    case 'PragmaticPlay':
                    case 'redtiger':
                    case 'EVOPLAY':
                        this.loading = true;
                        this.$router.push({
                            path: '/game/slot',
                            query: {
                                vendor: game.vendor,
                                game_id: game.id,
                            },
                        });
                        this.loading = false;
                        break;
                    default:
                        this.loading = true;
                        await this.launchGame(game);
                        this.loading = false;
                        break;
                }
            } else if (type == 'casino2') {
                this.selected_game_id = game.product;
                this.loading = true;
                await this.launchGame2(game, null, 'casino');
                this.loading = false;
            } else if (type == 'slot2') {
                this.selected_game_id = game.product;
                this.loading = true;
                this.$router.push({
                    path: `/game/slot`,
                    query: {
                        provider: game.prd_name,
                        product: game.prd_id,
                    },
                });
                this.loading = false;
            } else if (type == 'hilton') {
                this.selected_game_id = 'hilton';
                this.loading = true;
                await this.launchHiltonGame();
                this.loading = false;
            }
        },
        goTo(section_id) {
            this.$router.push({
                path: '/game',
                query: { section_id: section_id },
            });
        },
    },
    mounted() {
          try {
            if(this.$route.query.view && this.$route.query.view == 'games') {
                
                document.getElementById('games')
                    .scrollIntoView({
                        behavior: 'smooth',
                        inline: 'nearest',
                    });
            }
        } catch (e) {}
    },
};
</script>
