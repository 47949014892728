<template>
    <Layout>
        <div class="main-container">
            <div class="banner-container">
                <div class="banner-content">
                    <div class="container">
                        <h1 class="mb-2 mt-2 font-bold">
                            {{ $t('Welcome Bonus Chest') }}
                        </h1>
                        <p>
                            {{
                                $t(
                                    'Make your first deposit and receive a 100% match up to $700 with our Welcome Offer'
                                )
                            }}
                        </p>
                        <div
                            class="banner-buttons"
                            v-if="!$store.getters['auth/authenticated']">
                            <a
                                @click="showModal('auth-modal', 'register')"
                                href="javascript:void(0);"
                                class="btn btn-success btn-lg"
                                >{{ $t('register') }}</a
                            >
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="slider-container">
            <div class="slider scrollable">
                <div class="slider-group single">
                    <div class="slide">
                        <router-link to="/announcement">
                            <div class="slide-content">
                                <h3>{{ $t('announcement') }}</h3>
                                <p>Announcement</p>
                                <a
                                    href="javascript:void(0);"
                                    class="slide-button"></a>
                            </div>
                            <img
                                class="slide-image"
                                src="@/assets/img/slide-img/slide-img-7.png"
                                alt="" />
                        </router-link>
                    </div>
                    <div class="slide">
                        <a href="javascript:void(0);">
                            <div class="slide-content">
                                <h3>
                                    {{ $t('telegram announcement channels') }}
                                </h3>
                                <p>Telegram Channels</p>
                                <a
                                    href="javascript:void(0);"
                                    class="slide-button"></a>
                            </div>
                            <img
                                class="slide-image"
                                src="@/assets/img/slide-img/slide-img-8.png"
                                alt="" />
                        </a>
                    </div>
                    <div class="slide" onclick="window.open('https://t.me/herasol','_blank')">
                        <a href="javascript:void(0);">
                            <div class="slide-content">
                                <h3>{{ $t('telegram inquiries') }}</h3>
                                <p>Telegram</p>
                                <a
                                    href="javascript:void(0);"
                                    class="slide-button"></a>
                            </div>
                            <img
                                class="slide-image"
                                src="@/assets/img/slide-img/slide-img-9.png"
                                alt="" />
                        </a>
                    </div>
                </div>
                <div class="slider-group multiple twos">
                    <div class="slide">
                        <router-link to="/announcement">
                            <div class="slide-content">
                                <h3>{{ $t('announcement') }}</h3>
                                <p>Announcement</p>
                                <a
                                    href="javascript:void(0);"
                                    class="slide-button"></a>
                            </div>
                            <img
                                class="slide-image"
                                src="@/assets/img/slide-img/slide-img-7.png"
                                alt="" />
                        </router-link>
                    </div>
                    <div class="slide">
                        <a href="javascript:void(0);">
                            <div class="slide-content">
                                <h3>
                                    {{ $t('telegram announcement channels') }}
                                </h3>
                                <p>Telegram Channels</p>
                                <a
                                    href="javascript:void(0);"
                                    class="slide-button"></a>
                            </div>

                            <img
                                class="slide-image"
                                src="@/assets/img/slide-img/slide-img-8.png"
                                alt="" />
                        </a>
                    </div>
                </div>
                <div class="slider-group multiple twos" onclick="window.open('https://t.me/herasol','_blank')">
                    <div class="slide">
                        <a href="javascript:void(0);">
                            <div class="slide-content">
                                <h3>{{ $t('telegram inquiries') }}</h3>
                                <p>Telegram</p>
                                <a
                                    href="javascript:void(0);"
                                    class="slide-button"></a>
                            </div>
                            <img
                                class="slide-image"
                                src="@/assets/img/slide-img/slide-img-9.png"
                                alt="" />
                        </a>
                    </div>
                </div>
                <div class="slider-group multiple threes">
                    <div class="slide">
                        <router-link to="/announcement">
                            <div class="slide-content">
                                <h3>{{ $t('announcement') }}</h3>
                                <p>Announcement</p>
                                <a
                                    href="javascript:void(0);"
                                    class="slide-button"></a>
                            </div>
                            <img
                                class="slide-image"
                                src="@/assets/img/slide-img/slide-img-7.png"
                                alt="" />
                        </router-link>
                    </div>
                    <div class="slide">
                        <a href="javascript:void(0);">
                            <div class="slide-content">
                                <h3>
                                    {{ $t('telegram announcement channels') }}
                                </h3>
                                <p>Telegram Channels</p>
                                <a
                                    href="javascript:void(0);"
                                    class="slide-button"></a>
                            </div>

                            <img
                                class="slide-image"
                                src="@/assets/img/slide-img/slide-img-8.png"
                                alt="" />
                        </a>
                    </div>
                    <div class="slide" onclick="window.open('https://t.me/herasol','_blank')">
                        <a href="javascript:void(0);">
                            <div class="slide-content">
                                <h3>{{ $t('telegram inquiries') }}</h3>
                                <p>Telegram</p>
                                <a
                                    href="javascript:void(0);"
                                    class="slide-button"></a>
                            </div>
                            <img
                                class="slide-image"
                                src="@/assets/img/slide-img/slide-img-9.png"
                                alt="" />
                        </a>
                    </div>
                </div>
                <!-- <div class="slider-group multiple threes">
                    <div class="slide">
                        <router-link to="/coupon">
                            <div class="slide-content">
                                <h3>{{ $t('coupon') }}</h3>
                                <p>Coupons</p>

                                <a
                                    href="javascript:void(0);"
                                    class="slide-button"></a>
                            </div>
                            <img
                                class="slide-image"
                                src="@/assets/img/slide-img/slide-img-4.webp"
                                alt="" />
                        </router-link>
                    </div>
                </div> -->
                <!-- <div class="slider-group multiple fours">
                    <div class="slide">
                        <router-link to="/announcement">
                            <div class="slide-content">
                                <h3>{{ $t('announcement') }}</h3>
                                <p>Announcement</p>
                                <a
                                    href="javascript:void(0);"
                                    class="slide-button"></a>
                            </div>
                            <img
                                class="slide-image"
                                src="@/assets/img/slide-img/slide-img-7.png"
                                alt="" />
                        </router-link>
                    </div>
                    <div class="slide">
                        <router-link to="/transaction">
                            <div class="slide-content">
                                <h3>
                                    {{ $t('telegram announcement channels') }}
                                </h3>
                                <p>Telegram Channels</p>
                                <a
                                    href="javascript:void(0);"
                                    class="slide-button"></a>
                            </div>

                            <img
                                class="slide-image"
                                src="@/assets/img/slide-img/slide-img-8.png"
                                alt="" />
                        </router-link>
                    </div>
                    <div class="slide">
                        <router-link to="/bet">
                            <div class="slide-content">
                                <h3>{{ $t('telegram inquiries') }}</h3>
                                <p>Telegram</p>
                                <a
                                    href="javascript:void(0);"
                                    class="slide-button"></a>
                            </div>
                            <img
                                class="slide-image"
                                src="@/assets/img/slide-img/slide-img-7.png"
                                alt="" />
                        </router-link>
                    </div>
                </div> -->
            </div>
        </div>
        <GameGrid2/>
        <ProviderGrid />
    </Layout>
</template>
<script>
import Layout from '@/components/Layout.vue';
import GameGrid2 from '@/components/GameGrid2.vue';
import ProviderGrid from '@/components/ProviderGrid.vue';
import ui from '@/mixins/ui';
export default {
    name: 'HomeView',
    mixins: [ui],
    components: {
        Layout,
        GameGrid2,
        ProviderGrid,
    },
    mounted() {
        this.initScrollables();
    },
};
</script>
